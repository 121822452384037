@import "../vendor/bootstrap/scss/functions";

@import "variables_forward";
@import "variables_project";
@import "variables";
@import "support/mixins";

@import "../vendor/bootstrap/scss/bootstrap";
@import "support/bootstrap_vers_test";

@import "../vendor/Font-Awesome/scss/fontawesome.scss";
@import "../vendor/Font-Awesome/scss/solid.scss";
@import "../vendor/Font-Awesome/scss/brands.scss";

@import "variables_project_after_bs";

@import "support/utilities";
@import "colors";
@import "table";
@import "boxes";
@import "blog";
@import "code";
@import "nav";
@import "sidebar-tree";
@import "sidebar-toc";
@import "breadcrumb";
@import "alerts";
@import "content";
@import "search";
@import "main-container";
@import "blocks/blocks";
@import "section-index";
@import "pageinfo";
@import "taxonomy";
@import "drawio";
@import "shortcodes";
@import "swagger";
@import "support/rtl";


@if $td-enable-google-fonts {
  @import url($web-font-path);
}

.td-footer {
  @extend .td-box--dark;

  min-height: 150px;
  padding-top: map-get($spacers, 5);

  @include media-breakpoint-down(lg) {
    min-height: 200px;
  }

  /* &__left { } */

  &__center {
    @extend .small;
    text-align: center;
  }

  &__right {
    text-align: right;
  }

  &__about {
    font-size: initial;
  }

  &__links {
    &-list {
      @extend .list-inline;
      margin-bottom: 0;
    }

    &-item {
      @extend .list-inline-item;
      @extend .h3;

      a {
        color: inherit !important;
      }
    }
  }

  &__authors,
  &__all_rights_reserved {
    padding-left: map-get($spacers, 1);
  }

  &__all_rights_reserved {
    display: none;
  }
}

// Adjust anchors vs the fixed menu.
@include media-breakpoint-up(md) {
  .td-offset-anchor:target {
    display: block;
    position: relative;
    top: -4rem;
    visibility: hidden;
  }

  h2[id]:before,
  h3[id]:before,
  h4[id]:before,
  h5[id]:before {
    display: block;
    content: " ";
    margin-top: -5rem;
    height: 5rem;
    visibility: hidden;
  }
}

@import "styles_project";
